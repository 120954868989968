import { render, staticRenderFns } from "./NewUser.vue?vue&type=template&id=6c236280&scoped=true&"
import script from "./NewUser.vue?vue&type=script&lang=js&"
export * from "./NewUser.vue?vue&type=script&lang=js&"
import style0 from "./NewUser.vue?vue&type=style&index=0&id=6c236280&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c236280",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TLoginForm: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/app/LoginForm.vue').default,StateOptions: require('/var/lib/jenkins/workspace/Website Frontend - Production/components/StateOptions.vue').default})
