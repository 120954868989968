//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import states from '@/data/states.json';

export default {
  name: 'StateOptions',
  props: {
    label: {
      type: String,
      default: 'State',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    variant: {
      type: String,
      default: 'home',
    },
    labelKey: {
      type: String,
      default: 'id',
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    elementId: {
      type: String,
      default: 'state-options-select',
    },
  },
  data () {
    return {
      states,
      selectedOption: this.value[this.valueKey],
    };
  },
  watch: {
    value (newState) {
      this.selectedOption = newState[this.valueKey];
    },
  },
  mounted () {
    if (!this.$auth.loggedIn) {
      this.$nextTick(() => {
        this.setFirstOptionDisabled();
      });
    }
  },
  // This is how the v-model works when using nested components.
  // This is not always needed, but probably if a nested `v-model` is used.
  methods: {
    setFirstOptionDisabled () {
      console.log(this.elementId);
      const selectElement = document.getElementById(this.elementId);
      if (selectElement !== null) {
        const optionElement = selectElement.options[0];
        optionElement.setAttribute('disabled', 'disabled');
      }
    },
    setSelected (newValue) {
      this.$emit('input', getLocationState(newValue));
    },
  },
};

// doing this because we want a state object and not just the state abbreviation or the state name.
// we want a full object because we want to do some extra stuff with the data in other location and this
// allows the most dynamic approach.
function getLocationState (newState) {
  const selectedState = states.find(state => state.id === newState || state.name === newState);
  return selectedState && Object.freeze({
    ...selectedState,
    type: 'state',
  });
}
