//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { cartAdd, } from '@/services/iterable';
import ProfileProfessions from '@/components/account/ProfileProfessions';
import { checkoutStep2, } from '@/services/gtmManager';

export default {
  name: 'NewUser',
  components: { ProfileProfessions },
  props: {
    value: {
      type: Object,
      required: true,
    },
    sglCheckout: {
      type: Boolean,
      default: false,
    },
    companyName: {
      type: String,
      default: '',
    },
    sessionId: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      localAccount: { ...this.value },
      showLoginModal: false,
    };
  },
  computed: {
    localAccount_str () {
      return JSON.stringify(this.localAccount);
    },
  },
  watch: {
    // eslint-disable-next-line camelcase
    localAccount_str (newAccount_str, oldAccount_str) {
      /* this detects form autofill - watching zipcode/state for taxupdates on the cart
       * When deep watching objects, newValue and oldValue are identical, so I stringify-ed it:
       * turning the localAccount object to a string in computed and watching for changes on just
       * the string, then creating newValue/oldValue from that stringify-ed object.
       */
      const newAccount = JSON.parse(newAccount_str);
      const oldAccount = JSON.parse(oldAccount_str);
      if (typeof newAccount.zipcode !== 'undefined' && typeof newAccount.state !== 'undefined') {
        const updated = (newAccount.zipcode !== oldAccount.zipcode) || (newAccount.state.id !== oldAccount.state.id);
        const taxRequirements = newAccount.zipcode.length === 5 || newAccount.state.length >= 2;
        if (updated && taxRequirements) {
          this.$nextTick(() => {
            this.updateTax();
          });
        }
      }
    },
    localAccount: {
      handler (newAccount) {
        this.$emit('input', newAccount);
      },
      deep: true,
    },
  },
  mounted () {
    if (this.sglCheckout === true) {
      this.localAccount.company = this.companyName;
      this.localAccount.title = 'title';
    }
  },
  methods: {
    gtmPush () {
      try {
        checkoutStep2(this.$gtm);
      } catch (e) {
        console.log(e);
      }
    },
    markValid (element, minLength) {
      element = document.getElementById(element);
      if (element.value.length >= minLength) {
        if (element.id === 'email') {
          cartAdd(`${this.sessionId.startsWith('SUB') && !this.sessionId.startsWith('SUB.') ? 'SESSION' : 'COURSE'}`, `${this.sessionId}`, `${this.localAccount.email}`);
        }
        element.style.cssText = 'border-color: limegreen; background-color: #e7ffe7;';
      } else if (element.value.length <= minLength) {
        element.style.cssText = 'border-color: bg-gray-300; background-color: white;';
      }
    },
    updateTax () {
      if (this.localAccount.zipcode && this.localAccount.state && this.localAccount.zipcode.length === 5) {
        this.$emit('updateTax', this.localAccount);
      }
    },
    getLogin () {
      if (!this.$auth.user) {
        this.showLoginModal = true;
      }
    },
  },
};
